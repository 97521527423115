<template>
  <div class="engineering-bidding">
    <div class="body-content">
      <div class="com-header">
        <div class="title com-title">招投标信息</div>
      </div>
      <div class="com-body">
        <a-spin :spinning="spinning">
          <div class="card-content">
            <div class="empty__content" v-if="!cardList.length">
              <img src="@/assets/empty.png" alt />
              <span class="empty__description">登录成功的会员用户可查看</span>
            </div>
            <div v-for="item in cardList" :key="item.id" class="card-item-content" @click="handleToDetail(item)">
              <div class="card-item">
                <div class="card-left">
                  <div class="date">{{ formatTime(item.time, 'DD') }}</div>
                  <div class="time">{{ formatTime(item.time, 'YYYY-MM') }}</div>
                </div>
                <div class="card-right">
                  <div class="card-title" v-html="item.title" ></div>
                  <div class="card-type">信息来源：{{ item.source }}</div>
                  <div class="card-address">项目地址：{{ item.region }}</div>
                </div>
              </div>
            </div>
          </div>
        </a-spin>
      </div>
      <div class="load-more">
        <!-- v-show="$store.getters.isLogin" -->
        <div class="btn-theme-more" @click="$router.push('/bidding/bidding-list')">
          查看更多
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from '@/api/bidding/bidding'

export default {
  name: 'EngineeringBidding',
  inject: ['handleToRouter'],
  data () {
    return {
      cardList: [],
      spinning: false
    }
  },
  created () {
    this.handleGetData()
  },
  mounted () {
    this.$eventBus.$once('handleLayout', this.handleGetData)
  },
  beforeDestroy () {
    this.$eventBus.$off('handleLayout', this.handleGetData)
  },
  methods: {
    getAddress ({ province, city }) {
      if (city) {
        return province + '-' + city
      }
      return province || '- -'
    },
    handleGetData () {
      // this.spinning = true
      // if (this.$store.getters.isLogin) {
        getData({
          page: 1,
          limit: 6,
          tel: '15054273650',
          fid: '133',
          type: 1,
          source: '华能'
        })
          .then(res => {
            this.cardList = res.data
          })
          .finally(() => {
            this.spinning = false
          })
      // } else {
      //   this.cardList = []
      //   this.spinning = false
      // }
    },

    handleToDetail ({ id, time }) {
      if (this.handleIsLogin()) {
        window.open(`/bidding/bidding-details?id=${id}&time=${time}`)
      }
    }
  }
}
</script>

<style lang="less">
.engineering-bidding {
  width: 100%;
  background-image: url('../../../assets/home/bidding-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .body-content {
    width: 1200px;
    margin: 0 auto;
    .com-header {
      display: flex;
      align-items: center;
      height: 84px;
      border-bottom: 1px solid #dddddd;
    }
    .com-body {
      height: 540px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      .card-content {
        width: 1200px;
        height: 510px;
      }
    }
  }
  .card-item-content {
    margin: 0 20px 20px 0;
    display: inline-block;
    &:nth-child(2n) {
      margin-right: 0px;
    }
  }
  .card-item {
    width: 590px;
    height: 150px;
    display: flex;
    padding: 25px 20px;
    background: #ffffff;
    .card-left {
      width: 110px;
      height: 100px;
      border: 1px solid #dddddd;
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      .date {
        height: 66px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 28px;
        color: #333333;
      }
      .time {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #404040;
        color: #ffffff;
      }
    }
    .card-right {
      flex: 1;
      .card-title {
        width: 420px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        height: 37px;
        position: relative;
        margin-bottom: 11px;
        &::before {
          content: '';
          display: inline-block;
          height: 4px;
          width: 40px;
          position: absolute;
          left: 0;
          bottom: 0;
          background: #1677ff;
        }
      }
      .card-type {
        background: rgba(22, 119, 255, 0.1);
        border-radius: 2px;
        padding: 2px 6px;
        font-weight: 550;
        font-size: 12px;
        display: inline-block;
        color: #1677ff;
        margin-bottom: 11px;
      }
      .card-address {
        font-weight: 550;
        font-size: 14px;
        color: #666666;
      }
    }
  }
  .empty__content {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .empty__description {
      position: absolute;
      bottom: 25%;
      left: calc(50% - 108px);
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .load-more {
    height: 110px;
    width: 100%;
    .btn-theme-more {
      border: 1px solid #dddddd;
      border-radius: 40px;
      width: 160px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      margin: 0 auto;
      color: #999999;
      cursor: pointer;
    }
  }
}
</style>
