import request from '@/utils/request'

// const keyword = ['检修', '运行', '维护', '安装', '改造']
const keyword = ['']

function formatKeyword (text, area) {
  if (text && area) {
    return keyword.map((item) => item + '+' + text + '+' + area).join('，')
  }
  const data = text || area
  if (data) {
    return keyword.map((item) => item + '+' + data).join('，')
  }
  return keyword.join('，')
}

function formatResponse (response, parameter) {
  const dataList = response.data.map((item) => {
    let { title } = item
    keyword.forEach((data) => {
      if (title.includes(data)) {
        title = title.replaceAll(`<font color='red'>${data}</font>`, data)
      }
    })
    return {
      ...item,
      title: (parameter.type === '1' && parameter.keyword) ? title + "<font color='red'>[详情包含关注词]</font>" : title
    }
  })
  return {
    ...response,
    data: dataList
  }
}

// 招投标列表
export function getData (parameter) {
  return request
    .get('/api/back/tender/ddyptController/page', {
      params: {
        ...parameter,
        keyword: formatKeyword(parameter.keyword, parameter.area)
      }
    })
    .then((response) => formatResponse(response, parameter))
}

// 提交资料发布需求
export function EnterpriseMaterialDemateController (parameter) {
  return request({
    url: '/api/enterprise/employmentLearn/EnterpriseMaterialDemateController',
    method: 'post',
    data: parameter
  })
}
