<template>
  <div class="material-shop-card">
    <div class="body-content">
      <div class="com-header">
        <div class="title com-title">物资商城</div>
        <div class="nav-box">
          <tabNav :navList="navList" v-model="type" @input="handleGetData" />
        </div>
        <div class="search-box">
          <a-input-search
            placeholder="搜索商品名称"
            v-model="productName"
            enter-button
            @pressEnter="handleGetData"
            @search="handleGetData"
          />
        </div>
      </div>
      <div class="com-btn-box">
        <div class="btn-theme btn-theme-yellow" @click="handleToRouter('/supplies/materialData')">
          <div class="btn-icon">
            <img src="@/assets/home/car-icon.svg" alt />
          </div>
          <div class="btn-text">用模板采购</div>
        </div>
      </div>
      <div class="com-body">
        <a-spin :spinning="spinning">
          <a-empty class="empty" v-if="!dataList.length" description="物资商城维护中，敬请期待" />
          <div class="card-content" v-else>
            <div class="card-item" v-for="item in dataList" :key="item.productId" @click="handleToOpen(item)">
              <div class="avatar">
                <img v-imgLazy="item.pic" style="width: 216px; height: 216px;" >
                <!-- <img style="width: 216px; height: 216px;" :size="216" :src="item.pic" /> -->
              </div>
              <div class="card-bottom">
                <div class="name" :title="item.productName">{{ item.productName }}</div>
                <div class="price">￥{{ item.price }}</div>
                <div class="detail">
                  <span class="brand">品牌：{{ item.brand }}</span>
                </div>
                <div class="detail-btn">
                  <div class="btn-text">详情</div>
                  <div class="btn-icon"></div>
                </div>
              </div>
            </div>
          </div>
        </a-spin>
      </div>
      <div class="load-more">
        <div class="btn-theme-more" @click="handleToBaiscRouter('/supplies/supplies-shop')">查看更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import tabNav from './tab-nav.vue'
import { page } from '@/api/materialShop'

export default {
  name: 'MaterialShop',
  inject: ['handleToRouter', 'handleToBaiscRouter'],
  components: {
    tabNav
  },
  data () {
    return {
      navList: [
        {
          label: '全部',
          value: '0'
        },
        {
          label: '劳保',
          value: '1'
        },
        {
          label: '工具',
          value: '2'
        },
        {
          label: '备件',
          value: '3'
        },
        {
          label: '材料',
          value: '4'
        },
        {
          label: '办公用品',
          value: '6'
        },
        {
          label: '源头好品',
          value: '7'
        },
        {
          label: '二手物资',
          value: '5'
        }
      ],
      dataList: [],
      spinning: false,
      productName: '',
      type: '0'
    }
  },
  created () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      this.spinning = true
      page({
        pageIndex: 1,
        pageSize: 10,
        sort: 0,
        productName: this.productName,
        status: 1,
        type: this.type === '0' ? null : this.type
      })
        .then((res) => {
          this.dataList = res.data
        })
        .finally(() => {
          this.spinning = false
        })
    },
    handleToOpen (item) {
      window.open(`/supplies/supplies-details?id=${item.productId}`)
    }
  }
}
</script>

<style lang="less">
.material-shop-card {
  width: 100%;
  .body-content {
    width: 1200px;
    margin: 0 auto;
    .com-header {
      display: flex;
      align-items: center;
      height: 84px;
      justify-content: space-between;
      border-bottom: 1px solid #dddddd;
      .nav-box {
        height: 100%;
        flex: 1;
      }
    }
    .com-btn-box {
      height: 110px;
      display: flex;
      align-items: center;
    }
    .com-body {
      height: 780px;
      display: flex;
      align-items: center;
      justify-content: center;
      .card-content {
        height: 780px;
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
      }
    }
    .card-item {
      margin: 0 30px 30px 0;
      height: fit-content;
      &:nth-child(5n) {
        margin-right: 0px;
      }
      .card-bottom {
        width: 216px;
        border: 1px solid #eeeeee;
        position: relative;
        top: -1px;
        padding: 0px 10px;
        .name {
          width: 100%;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .price {
          font-weight: 400;
          font-size: 12px;
          color: #db2223;
        }
        .detail {
          font-weight: 300;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          height: 36px;
          -webkit-line-clamp: 2;
          color: #666666;
        }
        .line {
          display: inline-block;
          width: 2px;
          height: 12px;
          background: #666666;
          margin: 0 8px;
        }
        .name,
        .price,
        .detail {
          margin: 10px 0;
        }
        .detail-btn {
          width: 100%;
          border-top: 1px solid #eeeeee;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 41px;
          .btn-icon {
            width: 25px;
            height: 20px;
            background-image: url('../../../assets/home/right.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }
    }
    .load-more {
      height: 110px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-theme-more {
        border: 1px solid #dddddd;
        border-radius: 40px;
        width: 160px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        color: #999999;
        cursor: pointer;
      }
    }
  }
}
</style>
