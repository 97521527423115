var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'point-nav-content': true,
    'point-nav-content-top': _vm.scroll <= 526,
    'point-nav-content-right': _vm.scroll > 526
  }},[_c('div',{staticClass:"nav-content-box transtion-hover"},_vm._l((_vm.navList),function(item){return _c('div',{key:item.icon,class:{
        'transtion-hover': true,
        'nav-item': true,
        'nav-item-active': item.component === _vm.component
      },on:{"click":function($event){return _vm.handleScroll(item)}}},[_c('div',{staticClass:"nav-icon",style:(_vm.handleGetIcon(item.icon))}),_c('div',{staticClass:"nav-label"},[_c('div',{staticClass:"title-zh title"},[_vm._v(_vm._s(item.title.zh))]),_c('div',{staticClass:"title-en title"},[_vm._v(_vm._s(item.title.en))])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }