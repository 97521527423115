<template>
  <div
    :class="{
      'point-nav-content': true,
      'point-nav-content-top': scroll <= 526,
      'point-nav-content-right': scroll > 526
    }"
  >
    <div class="nav-content-box transtion-hover">
      <div
        :class="{
          'transtion-hover': true,
          'nav-item': true,
          'nav-item-active': item.component === component
        }"
        @click="handleScroll(item)"
        v-for="item in navList"
        :key="item.icon"
      >
        <div class="nav-icon" :style="handleGetIcon(item.icon)"></div>
        <div class="nav-label">
          <div class="title-zh title">{{ item.title.zh }}</div>
          <div class="title-en title">{{ item.title.en }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon1 from '@/assets/nav-icon/icon-1.png'
import icon2 from '@/assets/nav-icon/icon-2.png'
import icon3 from '@/assets/nav-icon/icon-3.png'
import icon4 from '@/assets/nav-icon/icon-4.png'
import icon5 from '@/assets/nav-icon/icon-5.png'

export default {
  name: 'PointNav',
  inject: ['getScrollTop'],
  data () {
    return {
      navList: [
        {
          icon: icon1,
          title: {
            zh: '灵活用工',
            en: 'Flexible employment'
          },
          component: 'flexible-employment'
        },
        {
          icon: icon2,
          title: {
            zh: '招投标信息',
            en: 'Tender information'
          },
          component: 'engineering-bidding'
        },
        {
          icon: icon3,
          title: {
            zh: '物资商城',
            en: 'The supply mall'
          },
          component: 'material-shop-card'
        },
        {
          icon: icon4,
          title: {
            zh: '资料商城',
            en: 'Data Mart'
          },
          component: 'data-shop-card'
        },
        {
          icon: icon5,
          title: {
            zh: '找钱',
            en: 'Change'
          },
          component: null
        }
      ],
      component: 'flexible-employment',
      offsetTopList: [],
      timer: null
    }
  },
  computed: {
    scroll () {
      return this.getScrollTop()
    }
  },
  watch: {
    scroll: {
      immediate: true,
      handler (newVal, oldVal) {
        const currentIndex = this.navList.findIndex(item => item.component === this.component)
        if (currentIndex < this.navList.length - 1 && newVal > oldVal) {
          const component = this.navList[currentIndex + 1].component
          if (component) {
            const offsetTop = document.body.querySelector(`.${component}`).offsetTop - 81
            this.component = newVal > offsetTop ? component : this.component
          }
        }
        if (currentIndex !== 0 && newVal < oldVal) {
          const component = this.navList[currentIndex - 1].component
          const offsetTop = document.body.querySelector(`.${component}`).offsetTop - 81
          this.component = newVal < offsetTop ? component : this.component
        }
      }
    }
  },
  methods: {
    handleGetIcon (icon) {
      return {
        backgroundImage: `url(${icon})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
      }
    },
    handleScroll ({ component }) {
      if (!component) {
        this.$message.error('功能正在开发中...')
      } else {
        this.component = component
        const comDom = document.body.querySelector(`.${component}`)
        const offsetTop = comDom.offsetTop - 81
        let currentScrollTop = this.getScrollTop()
        const scrollTop = offsetTop - currentScrollTop
        const top = scrollTop / 40
        this.timer && clearInterval(this.timer)
        this.timer = setInterval(() => {
          currentScrollTop += top
          if (currentScrollTop < offsetTop) {
            window.scrollTo(0, currentScrollTop)
          } else if (currentScrollTop > offsetTop) {
            window.scrollTo(0, offsetTop)
            clearInterval(this.timer)
          } else {
            window.scrollTo(0, offsetTop)
            clearInterval(this.timer)
          }
        }, 10)
      }
    }
  }
}
</script>

<style lang="less">
.slide-bck-br {
  -webkit-animation: slide-bck-br 0.45s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation: slide-bck-br 0.45s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

// @-webkit-keyframes slide-bck-br {
//   0% {
//     -webkit-transform: translateZ(0) translateY(0) translateX(0);
//     transform: translateZ(0) translateY(0) translateX(0);
//   }
//   100% {
//     -webkit-transform: translateZ(-400px) translateY(200px) translateX(200px);
//     transform: translateZ(-400px) translateY(200px) translateX(200px);
//   }
// }

.point-nav-content {
  z-index: 4;
  .nav-content-box,.nav-item{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-icon {
    width: 26px;
    height: 26px;
  }
  .title-zh {
    font-size: 18px;
  }
  .title-en {
    font-size: 14px;
  }
}
.point-nav-content-top {
  width: 100%;
  position: absolute;
  top: 606px;
  left: 0px;
  transform: rotate(0deg);
  .nav-content-box {
    display: flex;
    margin: 0 auto;
    width: 1200px;
    background: rgba(255, 255, 255, 0.46);
    backdrop-filter: blur(7px);

    .nav-item {
      width: 240px;
      height: 100px;
      font-weight: 400;
      color: #ffffff;
      transform: rotate(0deg);
      cursor: pointer;

      &:hover {
        background: rgba(22, 119, 255, 0.5);
      }
      &-active {
        background: #1677ff !important;
      }
      .nav-icon {
        margin-right: 13px;
      }
      .nav-label {
        display: block;
      }
    }
  }
}

@keyframes slide-bck-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
    transform: translateZ(0) translateY(-600px) translateX(-400px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(200px) translateX(200px);
    transform: translateZ(-400px) translateY(0px) translateX(0px) rotate(90deg);
  }
}

.point-nav-content-right {
  animation: slide-bck-br 0.45s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  position: fixed;
  right: -20px;
  bottom: 220px;
  transform: rotate(90deg);
  .nav-content-box {
    width: auto;
    height: auto;
    background: #222222;
    display: flex;
    align-items: center;
    .nav-item {
      width: 42px;
      height: 42px;
      background: #333333;
      transform: rotate(-90deg);
      cursor: pointer;
      &:hover {
        background: rgba(22, 119, 255, 0.5);
      }
      &-active {
        background: #1677ff !important;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      .nav-icon {
        margin-right: 0px;
      }
      .nav-label {
        display: none;
      }
    }
  }
}
</style>
