<template>
  <div class="carousel-content">
    <a-carousel :afterChange="handleChange" ref="carousel" :dots="false">
      <img src="@/assets/home/banner1.jpg" alt style="height: 706px" />
      <img src="@/assets/home/banner2.jpg" alt style="height: 706px" />
      <img src="@/assets/home/banner3.jpg" alt style="height: 706px" />
      <img src="@/assets/home/banner4.jpg" alt style="height: 706px" />
      <img src="@/assets/home/banner5.jpg" alt style="height: 706px" />
      <img src="@/assets/home/banner6.jpg" alt style="height: 706px" />
      <!-- <template v-for="item in bannerList">
        <img :key="item" v-imgLazy="item" alt style="height: 706px" />
      </template> -->
      <!-- <img v-imgLazy="'@/assets/home/banner1.png'" alt style="height: 706px" />
      <img v-imgLazy="'@/assets/home/banner2.png'" alt style="height: 706px" />
      <img v-imgLazy="'@/assets/home/banner3.png'" alt style="height: 706px" />
      <img v-imgLazy="'@/assets/home/banner4.png'" alt style="height: 706px" />
      <img v-imgLazy="'@/assets/home/banner5.png'" alt style="height: 706px" />
      <img v-imgLazy="'@/assets/home/banner6.png'" alt style="height: 706px" /> -->
    </a-carousel>
    <div class="carousel-btn">
      <div class="btn-content">
        <div class="btn-pre btn" @click="$refs.carousel.prev()">
          <img src="/img/pre.png" alt />
          <div class="btn-hover"></div>
        </div>
        <div class="btn-text">{{ active }}/6</div>
        <div class="btn-next btn" @click="$refs.carousel.next()">
          <img src="/img/next.png" alt />
          <div class="btn-hover"></div>
          <div class="box-left">
            <div
              :class="{
                'circle-left': true,
                'circle-animation-left': animation
              }"></div>
          </div>
          <div class="box-right">
            <div
              :class="{
                'circle-right': true,
                'circle-animation-right': animation
              }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel } from 'ant-design-vue'
import banner1 from '@/assets/home/banner1.jpg'
import banner2 from '@/assets/home/banner2.jpg'
import banner3 from '@/assets/home/banner3.jpg'
import banner4 from '@/assets/home/banner4.jpg'
import banner5 from '@/assets/home/banner5.jpg'
import banner6 from '@/assets/home/banner6.jpg'

export default {
  name: 'Carousel',
  components: {
    'a-carousel': Carousel
  },
  data () {
    return {
      active: 1,
      animation: false,
      timer: null,
      bannerList: [
       banner2, banner3, banner4, banner5, banner6
      ]
    }
  },
  mounted () {
    this.handleSetAnimation()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    handleChange (current) {
      this.active = current + 1
      this.animation = false
      this.handleSetAnimation()
    },
    handleSetAnimation () {
      setTimeout(() => {
        this.animation = true
      })
      this.timer && clearInterval(this.timer)
      this.timer = setTimeout(() => {
        this.$refs.carousel.next()
      }, 4000)
    }
  }
}
</script>

<style lang="less">
.carousel-content {
  .carousel-btn {
    position: absolute;
    z-index: 3;
    width: 100%;
    top: 552px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.05em;
    color: #ffffff;
    .btn-content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-text {
        margin: 0 12px;
      }
      .btn {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        &:hover {
          .btn-hover{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 34px;
            height: 34px;
            border: 2px solid rgba(255, 255, 255, 0.5);
            border-radius: 50%;
          }
        }
      }
      .btn-next {
        .box-left,
        .box-right {
          width: 50%;
          height: 100%;
          box-sizing: border-box;
          position: absolute;
          top: 0;
          overflow: hidden;
        }
        .box-left,
        .circle-left {
          left: 0;
        }
        .box-right,
        .circle-right {
          right: 0;
        }
        .circle-left,
        .circle-right {
          width: 200%;
          height: 100%;
          box-sizing: border-box;
          border: 2px solid #ffffff;
          border-radius: 50%;
          position: absolute;
          top: 0;
        }

        /*左边盒子里的圆圈初始时只显示右半边*/
        .circle-left {
          border-top-color: transparent;
          border-left-color: transparent;
          transform: rotate(-45deg);
        }
        /*右边盒子里的圆圈初始时只显示左半边*/
        .circle-right {
          border-bottom-color: transparent;
          border-right-color: transparent;
          transform: rotate(-45deg);
        }
        .circle-animation-left{
          animation: circleRotate 2s linear forwards 2s;
        }
        .circle-animation-right{
          animation: circleRotate 2s linear forwards;
        }
        @keyframes circleRotate {
          from {
            transform: rotate(-45deg);
          }
          to {
            transform: rotate(135deg);
          }
        }
      }
    }
  }
}
</style>
