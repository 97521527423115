<template>
  <div class="flexible-employment">
    <div class="body-content">
      <div class="com-header">
        <div class="title com-title">灵活用工</div>
        <div class="nav-box">
          <tabNav :navList="navList" v-model="type" @input="handleTypeChange" />
        </div>
        <div class="pagination-box">
          <a-pagination
            v-model="current"
            :showTotal="showTotal"
            :hideOnSinglePage="true"
            :total="total"
            :defaultPageSize="6"
            show-less-items
            size="small"
            @change="handlePageChange"
          />
        </div>
      </div>
      <div class="com-btn-box">
        <div class="btn-theme btn-theme-default" @click="handleToRouter('/employment/workDemandIndex')">
          <div class="btn-icon">
            <img src="@/assets/home/position.png" alt />
          </div>
          <div class="btn-text">发布岗位需求</div>
        </div>
        <!-- <div class="btn-theme btn-theme-default" @click="handleToRouter('/employment/workStaffing')">
          <div class="btn-icon">
            <img src="@/assets/home/project.png" alt />
          </div>
          <div class="btn-text">发布项目承揽</div>
        </div> -->
      </div>
      <div class="com-body">
        <a-spin :spinning="spining">
          <a-empty class="empty" v-if="!userList.length" />
          <!-- <div class="card-content" v-else>
            <div class="card-item" v-for="item in userList" :key="item.userId">
              <div class="card-top">
                <div class="avatar">
                  <a-avatar shape="square" :size="120" :src="item.img || defaultUser" />
                </div>
                <div class="user-detail">
                  <div class="user-name">{{ formatName(item.realName) }}</div>
                  <div class="user-skill" v-if="type === '2'">专业：{{ item.position }}</div>
                  <div class="user-content">
                    <div class="user-position">{{ type === '1' ? item.position : qualObj[item.qualificationCertificate] }}</div>
                    <div class="user-line"></div>
                    <div class="user-eval">{{ levelObj[item.skillLevelCertificate] }}</div>
                  </div>
                  <div class="user-skill" v-if="type === '1'">基本技能：{{ item.baseSkill }}</div>
                  <div class="user-experienced">项目经历：{{ workObj[item.experience] }}</div>
                </div>
              </div>
              <div class="card-bottom">
                <div class="user-label-title">专业技能：</div>
                <div class="user-label">
                  <div
                    class="user-label-item"
                    v-for="(data, index) in formatSkill( type === '1' ? item.professionalSkills : item.baseSkill)"
                    :key="index"
                  >{{ data }}</div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="post-center" v-else>
            <div class="post-item" v-for="(item, index) in userList" :key="item.id">
              <div class="post-img">
                <div class="img">
                  <img v-imgLazy="formatLogo(item.logo)" alt="" />
                </div>
              </div>
              <div class="post-title">
                <div class="title">
                  <div class="title-text">{{ item.wbProject || item.positionName }}</div>
                  <div class="title-price">{{ item.salary || `${item.wbProjectSalary}元` }}</div>
                </div>
                <div class="tag" v-if="type == 1">
                  <div>{{ BOARD_LODGING[item.boardLodging] }}</div>
                  <div>{{ TOLL[item.toll] }}</div>
                </div>
                <div v-else style="height: 32px"></div>
                <div style="font-size: 14px; margin-bottom: 4px">发布企业：{{ item.enterpriseName }}</div>
                <div style="font-size: 14px; margin-bottom: 4px">项目名称：{{ item.projectName }}</div>
                <div style="font-size: 14px; margin-bottom: 5px">
                  项目周期：<span>{{ formatTime(item.workStartTime || item.projectStartTime) }}</span
                  >-<span>{{ formatTime(item.workEndTime || item.projectEndTime) }}</span>
                </div>
              </div>
              <div class="post-button" @click="promptFlag = true">
                <div style="width: 22px; height: 22px; margin-top: 55px">
                  <div class="post-button_icon"></div>
                </div>
                <div class="post-button_text">立即申请</div>
              </div>
            </div>
          </div>
        </a-spin>
      </div>
    </div>
    <!-- 友情提示弹窗 -->
    <a-modal title="友情提示" :visible="promptFlag" width="1050px" @cancel="promptFlag = false">
      <template #footer></template>
      <div class="prompt-text">
        为给您带来更好的用工体验，推荐使用“有好活App”进行用工投递，使用App您将获得职位智能推荐、用工沟通、用工签约、考勤打卡、薪资获取、自我技能提升等一站式服务。
      </div>
      <div class="model-img" v-if="!isPreview" ><img src="@/assets/home/app-down.png" alt="" /></div>
      <div class="model-img" v-else><img src="@/assets/home/app-down-prod.png" alt="" /></div>
      <div class="model-text">使用手机扫一扫下载安装有好活app</div>
      <div class="model-prompt">*ios用户可在app store 搜索安装应用</div>
    </a-modal>
  </div>
</template>

<script>
import tabNav from './tab-nav.vue'
import defaultUser from '@/assets/default-user.png'
import { queryUserInfoByPaging } from '@/api/recruitUse'
import { BOARD_LODGING, TOLL } from '@/dict'
import moment from 'moment'
import defaultLogo from '@/assets/default-enter-logo.png'

const workObj = {
  1: '30万机组以下',
  2: '30-60万机组',
  3: '100万及以上机组'
}

const levelObj = {
  0: '无',
  1: '初级',
  2: '中级',
  3: '高级',
    '-1': '特殊工种证书'
}

const qualObj = {
  0: '无',
  1: '安全员',
  2: '建造师'
}

export default {
  name: 'FlexibleEmployment',
  inject: ['handleToRouter'],
  components: {
    tabNav
  },
  data () {
    return {
      workObj,
      levelObj,
      BOARD_LODGING,
      TOLL,
      qualObj,
      defaultUser,
      navList: [
        {
          label: '岗位需求',
          value: '1'
        }
        // {
        //   label: '项目承揽',
        //   value: '2'
        // }
      ],
      type: '1',
      current: 1,
      total: 0,
      userList: [],
      spining: false,
      promptFlag: false, // 友情提示状态值
      isPreview: process.env.NODE_ENV === 'preview'
    }
  },
  created () {
    this.handleGetData()
  },
  methods: {
    formatLogo (logo) {
      if (logo && logo.includes('https://cube.elemecdn.com')) {
        return defaultLogo
      }
      return logo
    },
    formatTime (time) {
      return time && moment(time).format('YYYY/MM/DD')
    },
    showTotal (total) {
      return `总数 ${total} 条`
    },
    formatName (name) {
      if (name.length === 2) {
        return name.substr(0, 1) + '*'
      } else if (name.length > 2) {
        var char = ''
        for (var i = 0, len = name.length - 2; i < len; i++) {
          char += '*'
        }
        return name.substr(0, 1) + char + name.substr(-1, 1)
      }
    },
    formatSkill (skills) {
      return skills ? skills.split('、') : []
    },
    handlePageChange (page) {
      this.current = page
      this.handleGetData()
    },
    handleTypeChange (value) {
      this.current = 1
      this.handleGetData()
    },
    handleGetData () {
      this.spining = true
      queryUserInfoByPaging({
        pageSize: 6,
        pageIndex: this.current,
        flag: this.type
      })
        .then((res) => {
          this.userList = res.data.map((item, index) => ({
            ...item,
            id: `${this.type}/${(res.pageIndex * res.pageSize + index)}`
          }))
          this.total = res.totalCount
        })
        .finally(() => {
          this.spining = false
        })
    }
  }
}
</script>

<style lang="less">
.flexible-employment {
  width: 100%;
  padding-bottom: 59px;
  .body-content {
    width: 1200px;
    margin: 0 auto;
  }
  .com-header {
    display: flex;
    align-items: center;
    height: 84px;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    .nav-box {
      height: 100%;
      flex: 1;
    }
    .pagination-box {
      .ant-pagination-item-active {
        background: #1677ff;
        border-radius: 4px;
        a {
          color: #ffffff;
        }
      }
    }
  }
  .com-btn-box {
    height: 110px;
    display: flex;
    align-items: center;
  }
  .com-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 484px;
    .card-content {
      width: 1200px;
      height: 484px;
      display: flex;
      flex-wrap: wrap;
    }
    .post-center {
      width: 1200px;
      // height: 400px;
      display: flex;
      flex-wrap: wrap;
    }
    .post-item {
      width: 580px;
      height: 170px;
      display: flex;
      margin-left: 20px;
      background: rgba(247, 249, 250, 1);
      border-radius: 10px;
      margin-top: 20px;
      transition: all 0.2s;
      .post-img {
        width: 155px;
        height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          width: 95px;
          height: 95px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .post-title {
        width: 320px;
        height: 170px;
        margin-top: 5px;
        .title {
          display: flex;
          justify-content: center;
          margin-top: 5px;
          .title-text {
            flex: 1;
            font-size: 20px;
            font-weight: 400;
            font-family: 'PingFang SC';
            font-style: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .title-price {
            flex: 1;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #e61818;
            text-align: end;
            margin-right: 10px;
          }
        }
        .tag {
          display: flex;
          color: #1677ff;
          div {
            background-color: rgba(22, 119, 255, 0.1);
            font-size: 12px;
            padding: 3px 3px 2px 2px;
            margin-right: 3px;
            margin-top: 4px;
            margin-bottom: 5px;
          }
          .tag-more {
            background-color: rgba(247, 249, 250, 1);
            margin-left: 5px;
            cursor: pointer;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
      .post-button {
        width: 105px;
        border-left: 1px dashed rgba(207, 221, 228, 1);
        .post-button_text {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          text-align: center;
        }
        .post-button_icon {
          width: 100%;
          height: 100%;
          margin-left: 40px;
          background-image: url('../../../assets/Vector.png');
          background-size: 100%;
        }
      }
    }
    .post-item:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      transform: translateY(-5px);
      .post-button {
        background-color: rgba(22, 119, 255, 1);
        border-radius: 0px 10px 10px 0px;
        color: #ffffff;
        cursor: pointer;
      }
      .post-button_icon {
        background-image: url('../../../assets/Vector1.png');
      }
    }
    .card-item {
      width: 386px;
      height: 221px;
      margin: 0 21px 21px 0;
      display: flex;
      padding: 30px 20px;
      background: #f7f9fa;
      border-radius: 10px;
      color: #666666;
      flex-direction: column;
      &:nth-child(3n) {
        margin-right: 0px;
      }
      .card-top {
        display: flex;
        align-items: center;
        .avatar {
          margin-right: 20px;
        }
        .user-detail {
          width: 186px;
          height: 120px;
          .user-name {
            font-weight: 400;
            font-size: 20px;
            color: #333333;
          }
          .user-content {
            display: flex;
            align-items: center;
            .user-line {
              height: 12px;
              width: 2px;
              margin: 0px 6px 0px 6px;
              background: #666666;
            }
          }
          .user-skill,
          .user-experienced,
          .user-content {
            margin-top: 8px;
          }
          .user-eval {
            color: rgba(229, 24, 24, 1);
          }
        }
      }
      .card-bottom {
        border-top: 1px dashed #cfdde4;
        margin-top: 17px;
        padding-top: 12px;
        display: flex;
        align-items: center;
        .user-label-title {
          min-width: 70px;
        }
        .user-label {
          width: 276px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          &-item {
            background: rgba(22, 119, 255, 0.1);
            border-radius: 2px;
            padding: 2px 6px;
            margin-right: 6px;
            display: inline-block;
            color: #1677ff;
          }
        }
      }
    }
  }
}
.prompt-text {
  width: 759px;
  height: 44px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin: 0 auto;
  color: #000000;
}
.ant-modal-title {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.model-img {
  width: 220px;
  height: 220px;
  margin: 30px auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.model-text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 0 auto;
}
.model-prompt {
  font-size: 12px;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}
</style>
