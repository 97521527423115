<template>
  <div class="home-index">
    <carousel />
    <pointNav />
    <flexibleEmployment />
    <engineeringBidding />
    <materialShop />
    <dataShop />
    <a-back-top class="home-back-top" :visibilityHeight="526" />
    <a-modal title="完善信息提醒" v-model="visible" width="500px" :footer="false">
      <div style="text-align: center">可前往“控制台-系统设置-企业名片-其它资质”上传完善更多企业资质。</div>
      <div
        style="text-align: center; margin-top: 12px; color: #40a9ff; cursor: pointer"
        class="modal-btn"
        @click="
          handleToBaiscRouter({
            name: 'systemEnterprise',
          })
        "
      >
        前往控制台>>
      </div>
    </a-modal>
    <a-popover placement="left" v-if="kfShow">
      <template slot="content">
        <span>在线客服</span>
      </template>
      <div class="zhiCustomBtn" @click="handleOpenIM">
        <img src="../../../public/kf.png" alt="" class="kf-icon" />
      </div>
    </a-popover>
  </div>
</template>

<script>
import carousel from './components/carousel.vue'
import pointNav from './components/point-nav.vue'
import flexibleEmployment from './components/flexible-employment.vue'
import engineeringBidding from './components/engineering-bidding.vue'
import materialShop from './components/material-shop.vue'
import dataShop from './components/data-shop.vue'
import { BackTop } from 'ant-design-vue'
export default {
  name: 'HomeIndex',
  components: {
    carousel,
    pointNav,
    flexibleEmployment,
    engineeringBidding,
    materialShop,
    dataShop,
    [BackTop.name]: BackTop
  },
  provide () {
    return {
      handleToRouter: this.handleToRouter,
      handleToBaiscRouter: this.handleToBaiscRouter
    }
  },
  beforeRouteLeave (to, from, next) {
    // ...
    const kfSDKDom = document.getElementById('zc__sdk__container')
    const kfBubbleDom = document.getElementById('zc__bubble_container')
    if (kfSDKDom) {
      kfSDKDom.style.scale = 0
    }
    if (kfBubbleDom) {
      kfBubbleDom.style.scale = 0
    }
    next()
  },
  data () {
    return {
      visible: false,
      kfShow: false
    }
  },
  created () {
    const isShowTips = window.localStorage.getItem('showTips')
    if (isShowTips) return
    const enterpriseInfoDto = this.userInfo?.enterpriseInfoDto
    if (this.isLogin && enterpriseInfoDto) {
      const data = enterpriseInfoDto?.otherLicense
      if (!data) return
      if (!JSON.parse(data).length) {
        this.visible = true
        window.localStorage.setItem('showTips', 'true')
      }
    }
  },
  mounted () {
    this.kfShow = !this.isLogin
    const kfSDKDom = document.getElementById('zc__sdk__container')
    const kfBubbleDom = document.getElementById('zc__bubble_container')
    if (kfSDKDom) {
      if (this.isLogin) {
        kfSDKDom.remove()
      } else {
        kfSDKDom.style.scale = 1
      }
    }
    if (kfBubbleDom) {
      if (this.isLogin) {
        kfBubbleDom.remove()
      } else {
        kfBubbleDom.style.scale = 1
      }
    }
  },
  methods: {
    handleOpenIM () {
      zc('frame_status', (e) => {
        console.log(e)
      })
    },
    handleToRouter (path) {
      if (this.handleIsLogin()) {
        this.$router.push(path)
      }
    },
    handleToBaiscRouter (path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less">
.home-index {
  position: relative;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  min-width: 1400px;
  overflow: auto;
  .com-title {
    width: 200px;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
  }
  .card-item {
    position: relative;
    transition: all 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    cursor: pointer;
    top: 0px;
    &:hover {
      top: -10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      background: #ffffff;
    }
  }
  .home-back-top {
    bottom: 85px;
    right: 67px;
  }
  .zhiCustomBtn {
    position: fixed;
    right: 67px;
    bottom: 37px;
    cursor: pointer;
    overflow: hidden;
    .kf-icon {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
